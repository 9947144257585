.App {
	text-align: center;
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100vw;
	max-width: 100%;
}

/* header section start */
header {
	height: 10vh;
	background-color: transparent;
	position: absolute;
	top: 0;
}
/* header section end */

/* nav section start */

/* nav section end */

/* landing screen start */

/* landing screen end */

.portfolio-container {
	display: flex;
	width: 100%;
	padding: 0 10%;
}

.landingscreen-container {
	height: 110vh;
	padding-bottom: 10vh;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
a,
button,
label {
	font-family: 'Bowlby One', cursive;
	color: #f9e81d;
	-webkit-text-stroke-width: 2px;
	-webkit-text-stroke-color: #2d3142;
}

p {
}

button:hover {
	cursor: pointer;
}

.util-text,
button {
	font-size: 20px;
}

.util-text,
h1,
h2,
h3,
h4,
h5,
h6,
button {
	letter-spacing: 0.5px;
}

h1 {
	font-size: 40px;
}

h2 {
	font-size: 24px;
}
.less {
	-webkit-text-stroke-width: 1px;
}

button {
	padding: 1.5rem 2rem;
	border-radius: 24px;
	background-color: #3e92cc;
	border: 4px solid #2d3142;
}

.hero-image {
	width: 24rem;
}

.dab-stage {
	width: 32rem;
	transform: translateX(-15%);
	max-width: unset;
}
.catch-stage {
	transform: translateX(2%) translateY(1%);
	z-index: 0;
}
.info-contaienr {
	z-index: 20;
}

.laptop-guide-rail-holder {
	top: 0;
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 5;
	padding-left: 1rem;
}
.laptop-guide-rail {
	width: 21.2rem;
	display: flex;
	margin: 0 auto;
	height: 100%;
	max-width: 100%;
}
.laptop-img {
	margin-top: -3.5rem;
}

.project-thumbnail-container {
	width: 100%;
	padding-bottom: 56.25%;
	position: relative;
}
.project-thumbnail-container img {
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	border: 4px solid #2d3142;
	border-radius: 24px;
}

.blue-styled-bg {
	background-color: #00b2ff;
}
.green-styled-bg {
	background-color: #00ff64;
}
.orange-styled-bg {
	background-color: #ff7600;
}

.big-border {
	border-radius: 24px;
	border: 4px solid #2d3142;
}
.project-description {
	-webkit-text-stroke-width: 2px;
	-webkit-text-stroke-color: #2d3142;
}

.skill-tiem,
.project-description {
}

.mega-title {
	font-size: 50px !important;
}

@media (max-width: 680px) {
	h1 {
		font-size: 36px;
	}
	.portfolio-container {
		padding: 0 5%;
	}

	.landing-screen-container {
		height: 90vh;
		margin-top: 10vh;
		top: 0;
	}

	.hero-image {
		width: 17rem;
	}
	.dab-stage {
		width: 22rem;
		transform: translateX(-9%);
	}

	.laptop-guide-rail {
		width: 14.75rem;
	}
	.laptop-guide-rail-holder {
		padding-left: 0.75rem;
	}
	.catch-stage {
		transform: translateX(2.25%) translateY(0%);
		z-index: 0;
	}

	.project-display-container .util-text,
	.project-display-container h1,
	.project-display-container h2,
	.project-display-container h3,
	.project-display-container h4,
	.project-display-container h5,
	.project-display-container h6,
	.project-display-container button {
		letter-spacing: 0px;
	}

	.project-display-container h1 {
		font-size: 32px;
	}
}

@media (max-width: 400px) {
	h1 {
		font-size: 31px;
	}
}
